@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  line-height: 1; }

ul, ol, li {
  list-style: none; }

img, video {
  vertical-align: top; }

a {
  text-decoration: none; }

table {
  border-collapse: collapse; }

.clear_fix:after {
  content: "";
  display: block;
  clear: both; }

table {
  border-collapse: collapse; }

input, textarea {
  outline: 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; }

.elli {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

textarea {
  resize: none; }

/* css 리셋 */
html {
  scroll-behavior: smooth; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

* {
  font-family: 'Noto Sans KR', sans-serif; }
  *::-webkit-scrollbar {
    width: 9px; }
  *::-webkit-scrollbar-thumb {
    background-color: #07C563; }

.scrollBar::-webkit-scrollbar {
  width: 2px;
  border-radius: 2px; }

.scrollBar::-webkit-scrollbar-track {
  /* 스크롤이 움직이는 영역  */
  background-color: #f9f9f9; }

.App {
  position: relative; }
  .App .loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000; }
    .App .loading.on {
      background-color: rgba(125, 125, 125, 0.5); }
    .App .loading .loadingSvg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #000; }
      .App .loading .loadingSvg svg {
        width: 50px;
        height: 50px; }
  .App .Navbar {
    position: fixed;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 100vh;
    z-index: 10;
    border-right: 1px solid #D9D9D9; }
    .App .Navbar .logo {
      margin: 30px 0; }
    .App .Navbar .gnb {
      width: 100%; }
      .App .Navbar .gnb ul li > a {
        display: block;
        width: 100%;
        height: 100%;
        padding-left: 14px;
        font-weight: 500;
        color: #474E56;
        font-size: 14px;
        line-height: 40px; }
        .App .Navbar .gnb ul li > a.active {
          background-color: #E8FCF0;
          position: relative;
          color: #1FC670;
          font-weight: 700; }
    .App .Navbar .userCash {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 22px 0; }
      .App .Navbar .userCash .userCashComponent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1px;
        width: 100%;
        padding: 0 15px 8px;
        color: #474E56;
        font-size: 12px; }
        .App .Navbar .userCash .userCashComponent .userCashName {
          width: 50%; }
        .App .Navbar .userCash .userCashComponent .userCashTime {
          width: 50%;
          color: #474E56;
          text-align: end; }
          .App .Navbar .userCash .userCashComponent .userCashTime:last-child {
            width: 60%; }
    .App .Navbar .Logout {
      width: 100%;
      border-top: 1px solid #E9E9E9;
      border-bottom: 1px solid #E9E9E9; }
      .App .Navbar .Logout > .logoutIcon {
        display: flex;
        align-items: center;
        cursor: pointer;
        line-height: 38px;
        padding-left: 14px;
        color: #B6B6B6;
        font-size: 12px; }
  .App .contentLayout {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .App .contentLayout .mainLayout {
      width: calc(100% - 200px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: fixed;
      top: 0;
      left: 200px;
      z-index: 100; }
      .App .contentLayout .mainLayout .TopList {
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 3px 5px rgba(225, 227, 229, 0.3); }
        .App .contentLayout .mainLayout .TopList .TopListTitle {
          width: 100%;
          height: 79px;
          display: flex;
          justify-content: space-between; }
          .App .contentLayout .mainLayout .TopList .TopListTitle h2 {
            font-weight: 700;
            font-size: 28px;
            color: #1c1c1c;
            margin-top: 32px;
            margin-bottom: 9px;
            padding-left: 104px; }
          .App .contentLayout .mainLayout .TopList .TopListTitle .sideMenu {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            margin-right: 110px;
            font-size: 14px; }
          .App .contentLayout .mainLayout .TopList .TopListTitle .atBatGridStyle svg {
            width: 31px; }
          .App .contentLayout .mainLayout .TopList .TopListTitle .gridList {
            width: 26px; }
            .App .contentLayout .mainLayout .TopList .TopListTitle .gridList .gridListA {
              color: #A7ACB8; }
              .App .contentLayout .mainLayout .TopList .TopListTitle .gridList .gridListA:active {
                color: #4A78D1; }
              .App .contentLayout .mainLayout .TopList .TopListTitle .gridList .gridListA.active svg {
                color: #4A78D1; }
        .App .contentLayout .mainLayout .TopList .TopListContent {
          width: 100%;
          line-height: 34px;
          border-top: 1px solid #B8B8B8;
          padding-left: 104px; }
          .App .contentLayout .mainLayout .TopList .TopListContent li a {
            width: 100%;
            height: 100%;
            font-weight: 400;
            color: #A6A6A6; }
            .App .contentLayout .mainLayout .TopList .TopListContent li a.active {
              font-weight: 500;
              color: #07C563; }
    .App .contentLayout .contentLayoutSection {
      margin-top: 50px;
      width: 100%;
      padding: 40px 50px 160px;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;
      position: relative;
      min-height: calc(100vh - 167px); }
      .App .contentLayout .contentLayoutSection > div {
        max-width: 1500px; }
  .App .loginRoot {
    background-image: url("../img/loginBg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: end; }
    .App .loginRoot .loginPage {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center; }
      .App .loginRoot .loginPage .LoginFormGroup {
        width: 850px;
        height: 455px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1); }
        .App .loginRoot .loginPage .LoginFormGroup .logoBox {
          width: 46%;
          height: 100%;
          background-image: url("../img/bg1.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center; }
        .App .loginRoot .loginPage .LoginFormGroup .inputRoot {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 13px;
          width: 54%;
          height: 100%;
          padding: 64px;
          background-color: #fff; }
          .App .loginRoot .loginPage .LoginFormGroup .inputRoot .inputWrap {
            width: 100%;
            display: flex;
            gap: 32px;
            align-items: center;
            justify-content: center; }
            .App .loginRoot .loginPage .LoginFormGroup .inputRoot .inputWrap svg {
              width: 20px;
              height: 24px;
              color: #A7ACB8; }
            .App .loginRoot .loginPage .LoginFormGroup .inputRoot .inputWrap .inputWraps {
              width: 100%; }
    .App .loginRoot footer {
      width: 100%; }

footer {
  width: 100%;
  background: #FEFEFF;
  padding: 23px 0 23px 128px; }
  footer > div ul {
    display: flex;
    gap: 3px;
    line-height: 15px;
    align-items: center; }
    footer > div ul li {
      font-size: 11px;
      color: #4D5B6B; }
      footer > div ul li span {
        margin-left: 3px; }

.atBatContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 1500px; }
  .atBatContainer .atBatListComponent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px 0; }

.cogButtonWrap {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 25px 0; }
  .cogButtonWrap > button {
    width: 214px; }

.buttonWrap {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px; }
  .buttonWrap > button {
    width: 234px; }

.atBatHistory {
  width: 100%; }
  .atBatHistory .filter {
    width: 96px; }
  .atBatHistory .listSection .listTop > p:nth-child(3) {
    flex: 1; }
  .atBatHistory .listSection .listTop > p:nth-child(5) {
    flex: 1; }
  .atBatHistory .listSection .listBot .listNumber > p:nth-child(3) {
    flex: 1; }
  .atBatHistory .listSection .listBot .listNumber > p:nth-child(5) {
    flex: 1; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.status .statusIsSuccess {
  width: 100%; }
  .status .statusIsSuccess .statusTop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .status .statusIsSuccess .statusTop .dateTop {
      width: 600px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .status .statusIsSuccess .statusTop .dateTop > p {
        font-size: 28px;
        font-weight: 700;
        margin: 0 37px;
        color: #1c1c1c; }
  .status .statusIsSuccess .statusBot {
    margin-top: 10px; }
    .status .statusIsSuccess .statusBot .btn:hover {
      background-color: #474E56;
      color: #fff;
      border-color: #474E56; }
    .status .statusIsSuccess .statusBot .statusBox {
      background-color: #fff;
      margin-top: 22px;
      width: 100%;
      height: 633px;
      display: flex;
      align-items: center;
      gap: 12px 0;
      border: 1px solid #D9D9D9;
      border-radius: 10px;
      overflow: hidden;
      justify-content: center; }
    .status .statusIsSuccess .statusBot .statusList {
      margin-top: 14px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 18px; }

.reservationHistory .dateFilter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -2%;
  margin-bottom: 25px; }
  .reservationHistory .dateFilter span {
    margin: 0 10px; }

.reservationHistory .filter {
  width: 98px; }

.reservationHistory .listSection .listTop > p {
  flex: 1; }

.reservationHistory .listSection .listBot .listNumber > p {
  flex: 1; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.customerSupport {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column; }
  .customerSupport .listTop p:first-child {
    flex: 1 !important; }
  .customerSupport .listTop p:nth-child(2) {
    flex: 7 !important; }
  .customerSupport .listTop p:last-child {
    flex: 1.5 !important; }
  .customerSupport .listNumber p:first-child {
    flex: 1 !important; }
  .customerSupport .listNumber p:nth-child(2) {
    flex: 7 !important;
    justify-content: flex-start !important; }
  .customerSupport .listNumber p:last-child {
    flex: 1.5 !important; }
  .customerSupport .customerSupportInput {
    width: 388px;
    display: flex;
    gap: 10px;
    margin-bottom: 15px; }
    .customerSupport .customerSupportInput .input {
      padding-left: 39px; }
  .customerSupport .customerSupportList {
    width: 100%; }
    .customerSupport .customerSupportList .customerSupportListTitle {
      height: 44px;
      background-color: #DCE7F1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #000000; }
      .customerSupport .customerSupportList .customerSupportListTitle > p {
        flex: 1;
        font-size: 16px; }
    .customerSupport .customerSupportList .customerSupportListContents .contentsList {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #999999; }
      .customerSupport .customerSupportList .customerSupportListContents .contentsList:nth-child(2n) {
        background-color: #fff; }
      .customerSupport .customerSupportList .customerSupportListContents .contentsList > p {
        flex: 1;
        text-align: center; }
        .customerSupport .customerSupportList .customerSupportListContents .contentsList > p:nth-child(2) {
          text-align: start; }

.customerSupportDatail {
  width: 100%; }
  .customerSupportDatail .customerSupportInput {
    width: 378px;
    display: flex;
    margin-bottom: 15px; }
    .customerSupportDatail .customerSupportInput .input {
      padding-left: 39px; }
  .customerSupportDatail .detailTitle {
    width: 100%;
    height: 76px;
    background-color: #D8DEE7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #000;
    font-size: 16px;
    font-weight: 500;
    color: #1C1C1C; }
  .customerSupportDatail .detailDate {
    width: 100%;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #999;
    font-size: 12px;
    color: #A7ACB8; }
    .customerSupportDatail .detailDate.noneFile {
      justify-content: end; }
  .customerSupportDatail .detailContents {
    padding: 45px 41px 207px;
    font-size: 14px;
    line-height: 19px;
    color: #1C1C1C;
    font-weight: 500;
    border-bottom: 1px solid #999; }
  .customerSupportDatail .listToBtn {
    width: 354px; }
  .customerSupportDatail .detailPrevNext {
    display: flex;
    border-top: 1px solid #999; }
    .customerSupportDatail .detailPrevNext:last-child {
      border-bottom: 1px solid #999; }
    .customerSupportDatail .detailPrevNext .prevNextTitle {
      background: #D8DEE7;
      width: 127px;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 41px; }
    .customerSupportDatail .detailPrevNext .prevNextContents {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 0 36px; }

.customerSupportFaq {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column; }
  .customerSupportFaq .faqBtnWrap {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: start;
    gap: 3px;
    margin-bottom: 15px;
    margin-top: 8px; }
    .customerSupportFaq .faqBtnWrap .btn {
      width: 103px;
      background-color: #fff; }
      .customerSupportFaq .faqBtnWrap .btn:hover {
        background-color: #07C563; }
      .customerSupportFaq .faqBtnWrap .btn.active {
        background-color: #07C563; }
  .customerSupportFaq .faqInput {
    width: 378px;
    display: flex;
    margin-bottom: 15px; }
    .customerSupportFaq .faqInput .input {
      padding-left: 39px; }
  .customerSupportFaq .faqList {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    background-color: #fff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 6px; }
    .customerSupportFaq .faqList .faqListTitle {
      position: relative;
      width: 100%;
      height: 63px;
      display: flex;
      align-items: center;
      padding: 0 130px;
      border-radius: 10px; }
      .customerSupportFaq .faqList .faqListTitle .q {
        color: #07C563; }
      .customerSupportFaq .faqList .faqListTitle:before {
        content: "";
        display: block;
        width: 3px;
        height: 34px;
        position: absolute;
        top: 50%;
        left: 13px;
        transform: translateY(-50%);
        background-color: #07C563;
        border-radius: 10px; }
      .customerSupportFaq .faqList .faqListTitle.active {
        background-color: #07C563 !important;
        color: #fff; }
        .customerSupportFaq .faqList .faqListTitle.active .q {
          color: #fff; }
        .customerSupportFaq .faqList .faqListTitle.active:before {
          background-color: #F4F4F4; }
    .customerSupportFaq .faqList .contentsList {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px; }
      .customerSupportFaq .faqList .contentsList .faqListContents {
        display: flex;
        align-items: center;
        background-color: #FAFAFA;
        padding: 40px 130px;
        border-radius: 10px; }
      .customerSupportFaq .faqList .contentsList:nth-child(2n-1) .faqListTitle {
        background-color: #F8F8F8; }
      .customerSupportFaq .faqList .contentsList:nth-child(2n) .faqListTitle {
        background-color: #FFF; }

.customerSupportQuestion {
  width: 100%;
  letter-spacing: -0.02em; }
  .customerSupportQuestion .QuestionBtnWrap {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: start;
    gap: 3px;
    margin-bottom: 15px;
    margin-top: 8px; }
    .customerSupportQuestion .QuestionBtnWrap .btn {
      width: 125px;
      background-color: #fff; }
      .customerSupportQuestion .QuestionBtnWrap .btn:hover {
        background-color: #474E56; }
      .customerSupportQuestion .QuestionBtnWrap .btn:active {
        background-color: #07C563; }
  .customerSupportQuestion .QuestionInput {
    width: 378px;
    display: flex;
    margin-bottom: 15px; }
    .customerSupportQuestion .QuestionInput .input {
      padding-left: 39px; }
  .customerSupportQuestion .QuestionList {
    width: 100%;
    font-weight: 500; }
    .customerSupportQuestion .QuestionList .contentsList {
      width: 100%; }
      .customerSupportQuestion .QuestionList .contentsList .listTop > p:nth-child(2) {
        flex: 6; }
      .customerSupportQuestion .QuestionList .contentsList .listNumber:before {
        display: none; }
      .customerSupportQuestion .QuestionList .contentsList .listNumber > p:nth-child(2) {
        flex: 6;
        justify-content: start; }
        .customerSupportQuestion .QuestionList .contentsList .listNumber > p:nth-child(2) svg {
          width: 24px;
          color: #464C51; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.storeStatistics {
  width: 100%; }
  .storeStatistics .lineChart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    padding: 28px; }
    .storeStatistics .lineChart .title {
      top: 28px;
      left: 28px;
      position: absolute;
      color: #474E56;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px; }
  .storeStatistics .pieChart {
    position: relative; }
    .storeStatistics .pieChart .title {
      position: absolute;
      top: 28px;
      left: 28px;
      font-size: 18px;
      font-weight: bold;
      color: #474E56; }
  .storeStatistics .StatisticsTop {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 20px; }
  .storeStatistics .StatisticsFilter {
    width: 98px;
    margin-bottom: 15px; }
    .storeStatistics .StatisticsFilter .btn {
      background-color: #fff; }
      .storeStatistics .StatisticsFilter .btn:hover {
        background-color: #474E56;
        color: #fff;
        border-color: #474E56; }
  .storeStatistics .StatisticsGraph .GraphPart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px; }
    .storeStatistics .StatisticsGraph .GraphPart.lineGraph .lineChart {
      padding: 100px 0; }
    .storeStatistics .StatisticsGraph .GraphPart.lineGraph .pieChart {
      padding: 30px 0;
      box-sizing: border-box; }
    .storeStatistics .StatisticsGraph .GraphPart .GraphPartChild {
      width: 100%;
      background: #fff;
      border: 1px solid #D9D9D9;
      border-radius: 10px;
      box-sizing: border-box; }
      .storeStatistics .StatisticsGraph .GraphPart .GraphPartChild .list {
        position: relative;
        display: flex;
        align-items: center;
        margin: 18px;
        height: 63px;
        border-radius: 10px; }
        .storeStatistics .StatisticsGraph .GraphPart .GraphPartChild .list:nth-child(2n+1) {
          background: #FAFAFA; }
        .storeStatistics .StatisticsGraph .GraphPart .GraphPartChild .list:before {
          content: "";
          display: block;
          width: 3px;
          height: 28px;
          background: #07C563;
          position: absolute;
          top: 50%;
          left: 38px;
          transform: translateY(-50%); }
        .storeStatistics .StatisticsGraph .GraphPart .GraphPartChild .list > div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 50px; }

.storeUserManagement {
  width: 100%; }
  .storeUserManagement .userManagementBtnWrap {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .storeUserManagement .userManagementBtnWrap .btn {
      background-color: #fff; }
      .storeUserManagement .userManagementBtnWrap .btn:hover {
        background-color: #474E56;
        color: #fff;
        border-color: #474E56; }
    .storeUserManagement .userManagementBtnWrap .userManagementBtn {
      width: 98px;
      margin-bottom: 15px; }
      .storeUserManagement .userManagementBtnWrap .userManagementBtn:last-child {
        width: 105px; }

.storeStaffManagement {
  width: 100%; }
  .storeStaffManagement .staffManagementBtn {
    width: 128px;
    margin-bottom: 15px; }
    .storeStaffManagement .staffManagementBtn .btn {
      background-color: #fff; }
      .storeStaffManagement .staffManagementBtn .btn:hover {
        background-color: #474E56;
        color: #fff;
        border-color: #474E56; }
  .storeStaffManagement .staffManagementGraph .listTop > p:first-child {
    flex: 0.7; }
  .storeStaffManagement .staffManagementGraph .listNumber > p:first-child {
    flex: 0.7; }

.storeInformation {
  width: 100%;
  height: fit-content;
  border: 1px solid #A7ACB8;
  border-radius: 10px;
  overflow: hidden;
  color: #474E56; }
  .storeInformation .storeInformationGraph {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #D9D9D9; }
    .storeInformation .storeInformationGraph .InformationGraphTitle {
      font-size: 12px;
      letter-spacing: -2%;
      color: #474E56;
      font-weight: 500; }
    .storeInformation .storeInformationGraph:last-child {
      border-bottom: none; }
    .storeInformation .storeInformationGraph .storeInformationGraphhaf {
      width: 100%;
      display: flex;
      height: 76px; }
      .storeInformation .storeInformationGraph .storeInformationGraphhaf > div {
        display: flex;
        align-items: center; }
      .storeInformation .storeInformationGraph .storeInformationGraphhaf:first-child {
        border-right: 1px solid #A7ACB8; }
      .storeInformation .storeInformationGraph .storeInformationGraphhaf .InformationGraphTitle {
        width: 131px;
        background-color: #D8DEE7;
        padding-left: 15px;
        border-bottom: 1px solid #A7ACB8;
        border-right: 1px solid #A7ACB8;
        box-sizing: border-box;
        margin-bottom: -1px; }
      .storeInformation .storeInformationGraph .storeInformationGraphhaf .InformationGraphContents {
        width: 100%;
        margin-left: 19px;
        margin-right: 41px;
        justify-content: center;
        flex: 1; }
    .storeInformation .storeInformationGraph .storeInformationGraphAddress {
      width: 100%;
      height: 326px;
      display: flex;
      align-items: center; }
      .storeInformation .storeInformationGraph .storeInformationGraphAddress .InformationGraphTitle {
        background-color: #D8DEE7;
        width: 131px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-right: 1px solid #A7ACB8;
        position: relative; }
        .storeInformation .storeInformationGraph .storeInformationGraphAddress .InformationGraphTitle:after {
          content: "";
          display: block;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          position: absolute;
          background: #A7ACB8; }
      .storeInformation .storeInformationGraph .storeInformationGraphAddress .InformationGraphContents {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 45px 19px 28px;
        color: #AAC0D7; }
        .storeInformation .storeInformationGraph .storeInformationGraphAddress .InformationGraphContents > p {
          background-color: #F0F6FC;
          width: 100%;
          height: 100%; }
        .storeInformation .storeInformationGraph .storeInformationGraphAddress .InformationGraphContents .address {
          width: 100%;
          height: 100%;
          background-color: #FAFAFA;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px dashed #474E56;
          border-radius: 5px;
          color: #B6B6B6;
          background-image: url("http://dp2exebkwdfgf.cloudfront.net/so/shop/master/map.PNG");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat; }
    .storeInformation .storeInformationGraph .storeInformationGraphPicture {
      width: 100%;
      height: 168px;
      height: 168px;
      display: flex;
      align-items: center; }
      .storeInformation .storeInformationGraph .storeInformationGraphPicture .InformationGraphTitle {
        background-color: #D8DEE7;
        width: 131px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-right: 1px solid #A7ACB8;
        position: relative; }
        .storeInformation .storeInformationGraph .storeInformationGraphPicture .InformationGraphTitle:after {
          content: "";
          display: block;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          position: absolute;
          background: #A7ACB8; }
      .storeInformation .storeInformationGraph .storeInformationGraphPicture .InformationGraphContents {
        padding: 21px 19px 17px; }
        .storeInformation .storeInformationGraph .storeInformationGraphPicture .InformationGraphContents .picture {
          width: 130px;
          height: 130px;
          position: relative;
          background-color: #FAFAFA;
          border: 1px dashed #474E56;
          border-radius: 5px;
          color: #B6B6B6; }
          .storeInformation .storeInformationGraph .storeInformationGraphPicture .InformationGraphContents .picture label {
            width: 130px;
            height: 130px;
            display: flex;
            cursor: pointer;
            flex-direction: column;
            align-items: center;
            justify-content: center; }
            .storeInformation .storeInformationGraph .storeInformationGraphPicture .InformationGraphContents .picture label input[type="file"] {
              display: flex;
              position: absolute;
              width: 1px;
              height: 1px;
              margin: -1px; }
          .storeInformation .storeInformationGraph .storeInformationGraphPicture .InformationGraphContents .picture.pictureIn {
            border: none; }
    .storeInformation .storeInformationGraph .storeInformationGraphParking {
      width: 100%;
      height: 67px;
      display: flex;
      align-items: center; }
      .storeInformation .storeInformationGraph .storeInformationGraphParking .InformationGraphTitle {
        background-color: #D8DEE7;
        width: 131px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-right: 1px solid #A7ACB8;
        position: relative; }
        .storeInformation .storeInformationGraph .storeInformationGraphParking .InformationGraphTitle:after {
          content: "";
          display: block;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          position: absolute;
          background: #A7ACB8; }
      .storeInformation .storeInformationGraph .storeInformationGraphParking .InformationGraphContents {
        padding: 17px 19px 10px; }
    .storeInformation .storeInformationGraph .storeInformationGraphInfo {
      width: 100%;
      height: 185px;
      display: flex;
      align-items: center; }
      .storeInformation .storeInformationGraph .storeInformationGraphInfo .InformationGraphTitle {
        background-color: #D8DEE7;
        width: 131px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-right: 1px solid #A7ACB8; }
      .storeInformation .storeInformationGraph .storeInformationGraphInfo .InformationGraphContents {
        flex: 1;
        width: 100%;
        height: 100%;
        padding: 15px 19px 20px; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.paymentSideMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; }
  .paymentSideMenu .atBatGridStyle {
    width: 100%; }
    .paymentSideMenu .atBatGridStyle svg {
      width: 31px; }
  .paymentSideMenu .gridList {
    width: 26px; }
    .paymentSideMenu .gridList .gridListA {
      color: #A7ACB8; }
      .paymentSideMenu .gridList .gridListA:active {
        color: #4A78D1; }
      .paymentSideMenu .gridList .gridListA.active svg {
        color: #4A78D1; }

.payment {
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px; }
  .payment .paymentHeader {
    padding-bottom: 40px;
    text-align: center; }
    .payment .paymentHeader h2 {
      font-size: 28px;
      color: #1C1C1C;
      margin-bottom: 40px; }
      .payment .paymentHeader h2 span {
        color: #07C563;
        font-weight: bold; }
    .payment .paymentHeader .paymentAddress {
      color: #A7ACB8; }
  .payment .paymentHistorySection {
    width: 738px;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 10px; }
    .payment .paymentHistorySection .paymentGraph .paymentGraphItem {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 90px;
      border-bottom: 1px solid #D9D9D9;
      padding: 30px 57px; }
      .payment .paymentHistorySection .paymentGraph .paymentGraphItem p:first-child {
        width: 70px; }
  .payment .paymentSection {
    width: 100%;
    letter-spacing: -0.02em;
    color: #1C1C1C; }
    .payment .paymentSection .paymentTitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-weight: 700;
      font-size: 28px; }
    .payment .paymentSection .paymentTitlefeeBWrap {
      width: 100%;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      font-size: 14px; }
      .payment .paymentSection .paymentTitlefeeBWrap .paymentTitlefeeBox {
        background-color: #fff;
        width: 100%;
        height: 100%;
        border: 1px solid #CED1DA;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 124px;
        letter-spacing: -2%; }
        .payment .paymentSection .paymentTitlefeeBWrap .paymentTitlefeeBox p {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 12px;
          font-weight: 500; }
        .payment .paymentSection .paymentTitlefeeBWrap .paymentTitlefeeBox.noColor svg {
          color: #B6B6B6 !important; }
        .payment .paymentSection .paymentTitlefeeBWrap .paymentTitlefeeBox svg {
          width: 24px;
          color: #4CB781; }
        .payment .paymentSection .paymentTitlefeeBWrap .paymentTitlefeeBox > span {
          font-weight: 700;
          font-size: 18px; }
        .payment .paymentSection .paymentTitlefeeBWrap .paymentTitlefeeBox:nth-child(2) svg {
          color: #EA6F6F; }
    .payment .paymentSection .paymentBtnWrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 12px; }
      .payment .paymentSection .paymentBtnWrap .btn {
        width: 96px; }
    .payment .paymentSection .paymentList {
      width: 100%;
      text-align: center; }
      .payment .paymentSection .paymentList .paymentListTitle {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        border-top: 1px solid #000;
        background-color: #DCE7F1; }
        .payment .paymentSection .paymentList .paymentListTitle > p {
          flex: 1; }
      .payment .paymentSection .paymentList .paymentListContents {
        width: 100%;
        height: 73px;
        border-bottom: 1px solid #999;
        display: flex;
        align-items: center; }
        .payment .paymentSection .paymentList .paymentListContents > p {
          flex: 1; }
        .payment .paymentSection .paymentList .paymentListContents:nth-child(2n+1) {
          background-color: #fff; }
    .payment .paymentSection .paymentChargeSection {
      display: flex;
      gap: 24px;
      margin-top: 31px; }
      .payment .paymentSection .paymentChargeSection .leftSection {
        width: 865px;
        height: 776px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #D9D9D9; }
        .payment .paymentSection .paymentChargeSection .leftSection.deposit {
          height: inherit !important; }
        .payment .paymentSection .paymentChargeSection .leftSection.sm {
          height: 722px; }
        .payment .paymentSection .paymentChargeSection .leftSection .title {
          position: relative;
          padding-left: 18px;
          font-weight: bold;
          font-size: 18px; }
          .payment .paymentSection .paymentChargeSection .leftSection .title span {
            color: #A7ACB8;
            font-size: 14px;
            margin-left: 10px; }
          .payment .paymentSection .paymentChargeSection .leftSection .title:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: #07C563; }
        .payment .paymentSection .paymentChargeSection .leftSection .paddingSection {
          padding: 44px 36px 44px 44px; }
          .payment .paymentSection .paymentChargeSection .leftSection .paddingSection .depositSection {
            width: 100%; }
            .payment .paymentSection .paymentChargeSection .leftSection .paddingSection .depositSection .depositWrite {
              border-top: 1px solid #474E56; }
        .payment .paymentSection .paymentChargeSection .leftSection .leftBotSection {
          border-top: 1px solid #D9D9D9; }
        .payment .paymentSection .paymentChargeSection .leftSection .paymentMethod {
          width: 100%;
          height: 58px;
          border: 1px solid #D9D9D9;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: start;
          padding-left: 22px; }
          .payment .paymentSection .paymentChargeSection .leftSection .paymentMethod svg {
            margin-left: 46px;
            margin-right: 24px; }
      .payment .paymentSection .paymentChargeSection .rightSection {
        flex: 1;
        height: 598px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #D9D9D9;
        position: relative; }
        .payment .paymentSection .paymentChargeSection .rightSection .title {
          position: absolute;
          width: 100%;
          background: #07C563;
          height: 46px;
          display: flex;
          align-items: center;
          padding-left: 22px;
          color: #fff;
          border-radius: 10px 10px 0 0;
          font-weight: bold;
          font-size: 18px; }
        .payment .paymentSection .paymentChargeSection .rightSection .paddingSection {
          padding: 24px; }
        .payment .paymentSection .paymentChargeSection .rightSection .paymentViewSection {
          margin-top: 46px;
          background: #FAFAFA;
          height: 266px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 32px;
          text-align: right;
          color: #1C1C1C; }
          .payment .paymentSection .paymentChargeSection .rightSection .paymentViewSection p {
            font-size: 28px;
            font-weight: bold;
            color: #07C563; }
        .payment .paymentSection .paymentChargeSection .rightSection .agreeSection {
          margin-top: 50px; }
          .payment .paymentSection .paymentChargeSection .rightSection .agreeSection .allCheckedSection {
            position: relative; }
            .payment .paymentSection .paymentChargeSection .rightSection .agreeSection .allCheckedSection .error {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              color: #E55A5A;
              font-size: 12px; }
      .payment .paymentSection .paymentChargeSection .inputSection {
        padding: 39px 41px;
        background: #FAFAFA;
        border-radius: 10px;
        margin-top: 20px; }
        .payment .paymentSection .paymentChargeSection .inputSection input {
          text-align: right;
          font-size: 18px;
          font-weight: bold;
          color: #07C563; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.mainPageSection {
  width: 100%; }
  .mainPageSection .atBat {
    width: 100%; }
    .mainPageSection .atBat .atBatComponent {
      width: 100%; }
      .mainPageSection .atBat .atBatComponent .atBatTitle {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .mainPageSection .atBat .atBatComponent .atBatTitle h3 {
          font-size: 18px;
          font-weight: bold;
          color: #474E56; }
        .mainPageSection .atBat .atBatComponent .atBatTitle .atBatMore {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 11px;
          color: #A7ACB8; }
          .mainPageSection .atBat .atBatComponent .atBatTitle .atBatMore .dot {
            width: 20px; }
      .mainPageSection .atBat .atBatComponent .atBatCardComponents {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 16px; }
        .mainPageSection .atBat .atBatComponent .atBatCardComponents .card {
          width: 285px;
          background: #fff;
          border: 1px solid #D9D9D9;
          border-radius: 10px;
          padding: 25px 20px;
          box-shadow: 4px 3px 8px 0px #E1E3E5BF; }
          .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 80px;
            padding-bottom: 11px;
            border-bottom: 3px solid #EAEAEA; }
            .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardTop .roomWrap {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: center; }
              .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardTop .roomWrap .roomName {
                font-size: 18px;
                font-weight: bold;
                color: #474E56; }
            .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardTop .person {
              font-size: 14px;
              color: #86909B; }
          .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardContents {
            margin-top: 22px; }
            .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardContents .red {
              color: #E55A5A; }
            .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardContents .cardContentsSection {
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 12px; }
              .mainPageSection .atBat .atBatComponent .atBatCardComponents .card .cardContents .cardContentsSection:last-child {
                margin-bottom: 0; }
  .mainPageSection .reservationWithNotice {
    width: 100%;
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 23px; }
    .mainPageSection .reservationWithNotice .reservation {
      background: #fff;
      border: 1px solid #D9D9D9;
      border-radius: 10px;
      width: 992px;
      height: 429px;
      padding: 30px 18px; }
      .mainPageSection .reservationWithNotice .reservation .reservationTop {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .mainPageSection .reservationWithNotice .reservation .reservationTop .titleWrap {
          display: flex;
          gap: 35px;
          align-items: center;
          margin-left: 10px; }
          .mainPageSection .reservationWithNotice .reservation .reservationTop .titleWrap h3 {
            font-size: 18px;
            font-weight: bold;
            color: #474E56; }
          .mainPageSection .reservationWithNotice .reservation .reservationTop .titleWrap .reservationNum {
            font-size: 18px;
            font-weight: bold;
            color: #474E56; }
            .mainPageSection .reservationWithNotice .reservation .reservationTop .titleWrap .reservationNum span {
              color: #07C563;
              margin-right: 4px; }
        .mainPageSection .reservationWithNotice .reservation .reservationTop .reservationMore {
          display: flex;
          align-items: center;
          color: #A7ACB8;
          font-size: 14px;
          gap: 10px; }
          .mainPageSection .reservationWithNotice .reservation .reservationTop .reservationMore .dot {
            width: 20px; }
      .mainPageSection .reservationWithNotice .reservation .reservationContents {
        margin-top: 14px; }
        .mainPageSection .reservationWithNotice .reservation .reservationContents .contentsTitle {
          font-size: 14px;
          color: #86909B;
          font-weight: bold;
          background: #FAFAFA;
          padding: 17px 0px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .mainPageSection .reservationWithNotice .reservation .reservationContents .contentsTitle > div {
            flex: 1;
            text-align: center; }
        .mainPageSection .reservationWithNotice .reservation .reservationContents .contentsList {
          font-size: 14px;
          color: #1C1C1C; }
          .mainPageSection .reservationWithNotice .reservation .reservationContents .contentsList .listA:last-child .contentsItem {
            border-bottom: none; }
          .mainPageSection .reservationWithNotice .reservation .reservationContents .contentsList .contentsItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EAEAEA;
            height: 56px; }
            .mainPageSection .reservationWithNotice .reservation .reservationContents .contentsList .contentsItem > div {
              flex: 1;
              text-align: center; }
    .mainPageSection .reservationWithNotice .notice {
      background: #fff;
      border: 1px solid #D9D9D9;
      border-radius: 10px;
      width: 484px;
      height: 429px;
      padding: 24px 19px; }
      .mainPageSection .reservationWithNotice .notice .noticeTop {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px; }
        .mainPageSection .reservationWithNotice .notice .noticeTop .titleWrap {
          display: flex;
          gap: 35px;
          align-items: center;
          margin-left: 9px; }
          .mainPageSection .reservationWithNotice .notice .noticeTop .titleWrap h3 {
            font-size: 18px;
            font-weight: bold;
            color: #474E56; }
          .mainPageSection .reservationWithNotice .notice .noticeTop .titleWrap .reservationNum {
            font-size: 18px;
            font-weight: bold;
            color: #474E56; }
            .mainPageSection .reservationWithNotice .notice .noticeTop .titleWrap .reservationNum span {
              color: #07C563;
              margin-right: 4px; }
        .mainPageSection .reservationWithNotice .notice .noticeTop .noticeMore {
          display: flex;
          align-items: center;
          color: #A7ACB8;
          font-size: 14px;
          gap: 10px; }
          .mainPageSection .reservationWithNotice .notice .noticeTop .noticeMore .dot {
            width: 20px; }
      .mainPageSection .reservationWithNotice .notice .noticeContents {
        display: flex;
        flex-direction: column;
        gap: 4px; }
        .mainPageSection .reservationWithNotice .notice .noticeContents .contentsItem {
          position: relative;
          height: 63px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 27px 20px 35px;
          box-sizing: border-box;
          font-size: 16px;
          color: #474E56; }
          .mainPageSection .reservationWithNotice .notice .noticeContents .contentsItem:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 13px;
            transform: translateY(-50%);
            width: 3px;
            height: 34px;
            background: #07C563; }
          .mainPageSection .reservationWithNotice .notice .noticeContents .contentsItem:nth-child(2n+1) {
            background: #FAFAFA;
            border-radius: 10px; }
  .mainPageSection .chart {
    display: flex;
    gap: 24px;
    margin-top: 23px; }
    .mainPageSection .chart > div {
      background: #fff;
      border: 1px solid #D9D9D9;
      border-radius: 10px;
      height: 430px; }
    .mainPageSection .chart .lineChart {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1; }
      .mainPageSection .chart .lineChart .title {
        position: absolute;
        top: 28px;
        left: 28px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 30px; }
    .mainPageSection .chart .pieChart {
      position: relative;
      width: 480px; }
      .mainPageSection .chart .pieChart .title {
        position: absolute;
        top: 28px;
        left: 28px;
        font-size: 18px;
        font-weight: bold; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.topRefreshWrap {
  position: fixed;
  bottom: 160px;
  right: 50px;
  z-index: 16; }
  .topRefreshWrap .btnWrap {
    display: flex;
    flex-direction: column;
    gap: 4px; }
    .topRefreshWrap .btnWrap button {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background-color: #474E56;
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.03em;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25); }
      .topRefreshWrap .btnWrap button:nth-child(2) {
        background-color: #4ED284;
        display: flex;
        align-items: center;
        justify-content: center; }
        .topRefreshWrap .btnWrap button:nth-child(2) svg {
          height: 24px;
          width: 24px; }
  .topRefreshWrap .refreshWrap .refreshMsg {
    background-color: #2C2C2D;
    position: absolute;
    left: -433px;
    bottom: 24px;
    transform: translateY(50%);
    border-radius: 3px;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #fff;
    width: 417px;
    height: 40px;
    line-height: 40px;
    display: none;
    align-items: center;
    justify-content: center; }
    .topRefreshWrap .refreshWrap .refreshMsg span {
      position: absolute;
      right: -11px;
      color: #2C2C2D;
      font-size: 13px;
      transform: rotate(90deg); }
  .topRefreshWrap .refreshWrap:hover .refreshMsg {
    display: flex; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.input {
  border: 1px solid #A7ACB8;
  border-radius: 5px;
  font-size: 12px;
  color: #1c1c1c;
  background-color: #fff; }
  .input::placeholder {
    color: #A7ACB8; }
  .input.sm {
    padding: 12px 15px; }
  .input.lg {
    padding: 15px 15px; }
  .input:hover {
    border-color: #374050; }
  .input:focus {
    border-color: #4ED284; }
  .input.error {
    border-color: #DC0E0E; }
    .input.error ~ .error-massage {
      display: block; }
  .input.disabled {
    border-color: #A7ACB8;
    background-color: #EFEFEF;
    color: #A7ACB8;
    cursor: not-allowed; }
  .input.success {
    border-color: #3DC292; }

.error-massage {
  display: none;
  position: absolute;
  margin-top: 7px;
  font-size: 10px;
  color: #DC0E0E; }

.gridBox, .listBox {
  box-shadow: 4px 3px 8px rgba(225, 227, 230, 0.75); }

.selectlist::-webkit-scrollbar {
  width: 5px;
  height: 93px;
  position: absolute;
  top: 0;
  right: 0; }

.selectlist::-webkit-scrollbar-track {
  margin: 10px 0;
  background-color: #E3E3E3;
  border-radius: 60px; }

.selectlist::-webkit-scrollbar-thumb {
  background-color: #C0C0C0;
  border-radius: 60px; }

.selectRoot {
  width: 100%; }
  .selectRoot .select {
    padding: 0 15px;
    border-radius: 5px;
    font-size: 12px;
    color: #1c1c1c;
    border: 1px solid #1c1c1c; }
    .selectRoot .select:focus {
      border-color: #4ED284; }
    .selectRoot .select.lg {
      height: 60px; }
    .selectRoot .select.sm {
      height: 48px; }
    .selectRoot .select.default {
      color: #474E56;
      border-color: #A7ACB8;
      border-radius: 5px;
      width: 100%;
      padding: 5px 12px;
      text-align: left;
      background-color: #fff; }
      .selectRoot .select.default:focus {
        border-color: #4ED284 !important; }
      .selectRoot .select.default:hover {
        border-color: #474E56; }
    .selectRoot .select.disabled {
      border-color: #A7ACB8;
      background-color: #EFEFEF;
      color: #A7ACB8;
      cursor: not-allowed; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.listSectionTop {
  width: 100%;
  color: #1C1C1C; }
  .listSectionTop .filter .btn {
    background-color: #fff; }
    .listSectionTop .filter .btn:hover {
      background-color: #474E56;
      color: #fff;
      border-color: #474E56; }
  .listSectionTop .listSection {
    background-color: #FFFFFF;
    margin-top: 14px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding-bottom: 35px; }
    .listSectionTop .listSection .listTop {
      display: flex;
      border-radius: 10px 10px 0 0;
      background-color: #fff;
      margin: 0 20px; }
      .listSectionTop .listSection .listTop > p {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        font-size: 16px;
        font-weight: 500; }
    .listSectionTop .listSection .reply > div {
      border-radius: 10px; }
    .listSectionTop .listSection .listNumber {
      position: relative;
      display: flex;
      margin: 0 20px;
      box-sizing: border-box;
      border-radius: 10px; }
      .listSectionTop .listSection .listNumber:before {
        content: "";
        display: block;
        width: 3px;
        height: 34px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 13px;
        background-color: #07C563;
        border-radius: 10px; }
      .listSectionTop .listSection .listNumber > p {
        flex: 2;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px; }
      .listSectionTop .listSection .listNumber:nth-child(2n-1) {
        background-color: #FAFAFA; }
      .listSectionTop .listSection .listNumber:nth-child(2n) {
        background-color: #FFF; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.checkBoxLabel {
  flex: 0 1 83px;
  position: relative; }
  .checkBoxLabel input {
    display: none; }
  .checkBoxLabel .label {
    background-color: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px solid #D9D9D9; }
  .checkBoxLabel input[type="radio"]:checked + .label {
    transform: translateY(-50%);
    display: block;
    z-index: 1;
    background-color: #4ED284;
    border: 1px solid #4ED284; }
    .checkBoxLabel input[type="radio"]:checked + .label .radioInput {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      z-index: -1; }
  .checkBoxLabel.disabled {
    cursor: not-allowed; }
    .checkBoxLabel.disabled label {
      background-color: #EFEFEF; }

.selectPerson input[type="checkbox"] {
  display: none; }
  .selectPerson input[type="checkbox"]:checked + label {
    background-color: #07C563;
    border-color: #07C563; }
    .selectPerson input[type="checkbox"]:checked + label span {
      display: block; }

.selectPerson label {
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  z-index: 1; }
  .selectPerson label span {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    display: none;
    color: #fff; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.btn {
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #474E56;
  color: #474E56;
  padding: 0 10px; }
  .btn.sm {
    height: 32px; }
  .btn.ml {
    height: 36px; }
  .btn.md {
    height: 42px; }
  .btn.lg {
    height: 52px; }
  .btn.xxl {
    height: 61px;
    font-size: 18px;
    font-weight: 800;
    color: #fff;
    background-color: #474E56; }
    .btn.xxl:hover {
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25); }
  .btn.submit {
    height: 50px;
    background-color: #4ED284;
    border: none;
    color: #fff;
    font-weight: 700;
    font-size: 12px; }
    .btn.submit.active {
      background-color: #4ED284; }
    .btn.submit:hover {
      background-color: #474E56; }
  .btn:hover {
    background-color: #474E56;
    color: #fff;
    border-color: #474E56; }
  .btn:active {
    background-color: #4ED284;
    color: #fff;
    border-color: #4ED284; }
  .btn.active {
    background-color: #4ED284;
    color: #fff;
    border-color: #4ED284; }
  .btn.focus {
    background-color: #4ED284;
    color: #fff;
    border-color: #4ED284; }
  .btn.disabled {
    border-color: #A7ACB8;
    background-color: #EFEFEF;
    color: #A7ACB8;
    cursor: not-allowed; }
    .btn.disabled:hover {
      border: #A7ACB8;
      background-color: #EFEFEF;
      color: #A7ACB8;
      cursor: not-allowed; }

.react-datepicker {
  font-size: 1.3rem !important; }

.react-datepicker__month-container {
  width: 100%; }

.react-datepicker__current-month {
  font-size: 1.5rem !important; }

.react-datepicker__header--custom {
  padding-top: 6px !important;
  width: 100%; }

.react-datepicker__day--selected {
  background-color: #07C563;
  width: 100%;
  height: 100%;
  color: #fff; }

.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -3%;
  color: #1c1c1c;
  margin-bottom: 8px; }

.react-datepicker__day-names {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  font-size: 13px;
  font-weight: 400px;
  line-height: 18px;
  letter-spacing: -3%;
  color: #A7ACB8; }

.react-datepicker__navigation {
  top: 13px !important; }

.react-datepicker__day-name, .react-datepicker__day {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; }

.saturday {
  color: blue !important; }

.sunday {
  color: red !important; }

.react-datepicker__day--disabled {
  color: #cccccc !important; }

.react-datepicker-popper {
  width: 390px;
  z-index: 100;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #A7ACB8;
  box-shadow: 2px 2px 16px rgba(130, 130, 130, 0.15);
  padding: 0 45px; }

.dateSelect {
  height: 100%;
  border: 1px solid #A7ACB8;
  border-radius: 5px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -3%;
  padding: 0 8px; }

.wrapperButton .react-datepicker__input-container {
  background-image: none !important;
  background-color: transparent;
  border: none;
  padding: 10px; }

.react-datepicker__input-container {
  background-color: #fff;
  background-image: url("../img/DatePicker.svg");
  background-repeat: no-repeat;
  background-position: 15px, center;
  border: 1px solid #A7ACB8;
  color: #374050;
  padding: 12px 15px;
  border-radius: 5px; }
  .react-datepicker__input-container:active {
    border-color: #4A78D1; }
  .react-datepicker__input-container .atBatInput {
    width: 181px; }
  .react-datepicker__input-container input {
    width: 130px;
    color: #374050;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-left: 27px; }
    .react-datepicker__input-container input::placeholder {
      color: #A7ACB8; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.modalRoot {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #2C2C2C99;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000; }
  .modalRoot.zIndexHigher {
    z-index: 10001; }
  .modalRoot .modal {
    background-color: #fff;
    transform: translate(-50%, -50%);
    border-radius: 20px 20px 10px 10px;
    font-weight: 500;
    position: fixed;
    top: 50%;
    left: 50%; }
    .modalRoot .modal .modalhead {
      height: 53px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #D8DEE7;
      border-radius: 10px 10px 0 0;
      padding: 0 30px;
      margin-top: -2px; }
      .modalRoot .modal .modalhead .modalTitle {
        width: 100%;
        font-size: 18px;
        color: #474E56;
        font-weight: 700; }
    .modalRoot .modal .modalContent {
      padding-top: 12px; }
    .modalRoot .modal.xs {
      width: 450px; }
    .modalRoot .modal.sm {
      width: 502px; }
    .modalRoot .modal.lg {
      width: 674px; }
    .modalRoot .modal.xxl {
      width: 1240px; }
      .modalRoot .modal.xxl .modalIn {
        display: flex;
        flex-direction: inherit;
        align-items: flex-start; }
        .modalRoot .modal.xxl .modalIn .modalInWrap {
          width: 768px; }
    .modalRoot .modal .modalIn {
      margin: 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 0 22px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      letter-spacing: -0.02em; }
      .modalRoot .modal .modalIn .modalList {
        display: flex;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #EAEAEA; }
        .modalRoot .modal .modalIn .modalList:first-child {
          border-top: 1px solid #1C1C1C; }
        .modalRoot .modal .modalIn .modalList .listName {
          display: flex;
          width: 117px;
          height: 79px;
          background-color: #FAFAFA;
          align-items: center;
          padding-left: 30px; }
        .modalRoot .modal .modalIn .modalList .listInput {
          display: flex;
          align-items: center;
          width: 100%;
          flex: 1;
          justify-content: center;
          padding-left: 55px;
          padding-right: 38px; }
          .modalRoot .modal .modalIn .modalList .listInput > span {
            padding: 0 16px; }
          .modalRoot .modal .modalIn .modalList .listInput .react-datepicker__input-container {
            width: 100%; }
        .modalRoot .modal .modalIn .modalList .listSelect {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 4px;
          flex: 1;
          padding-left: 55px;
          padding-right: 38px; }
        .modalRoot .modal .modalIn .modalList .listNameTextArea {
          display: flex;
          width: 117px;
          background-color: #F9F9F9;
          align-items: center;
          padding-left: 30px;
          line-height: 16px;
          font-size: 14px; }
        .modalRoot .modal .modalIn .modalList .listTextArea {
          height: 177px;
          margin: 13px 38px 18px 52px; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.bg-Primal {
  background-color: #4CB781; }

.bg-Wait {
  background-color: #B6B6B6; }

.bg-Practice {
  background-color: #A86CF3; }

.bg-NineHole {
  background-color: #1C9ED6; }

.bg-EighteenHole {
  background-color: #07C563; }

.bg-End {
  background-color: #ED7087; }

.bg-Loading {
  background-color: #ECD596; }

.bg-Reservation {
  background-color: #AFB8C5; }

.bg-ReservationStart {
  background-color: #4CB781; }

.bg-ReservationEnd {
  background-color: #EA6F6F; }

.bgGray {
  background-color: #D8DEE7; }

.bgLayout {
  background-color: #F4F4F4; }

.bgGray_fa {
  background-color: #FAFAFA; }

.bgGreen {
  background-color: #07C563; }

.bgLightGreen {
  background-color: #4ED284; }

.bgBlack {
  background-color: #474E56; }

.bgMint {
  background-color: #E8FCF0; }

.badgeGray {
  background-color: #F5F5F5; }

.bgPink {
  background-color: #FFF3F5; }

.textBlack {
  color: #474E56; }

.textIconGray {
  color: #D8DEE7; }

.textBlack_1c {
  color: #1C1C1C; }

.textGray {
  color: #86909B; }

.textDarkGray {
  color: #474E56; }

.textGreen {
  color: #07C563 !important; }

.textLightGray {
  color: #A7ACB8; }

.textRed {
  color: #ED7087 !important; }

.textRed2 {
  color: #E55A5A !important; }

.borderBlack {
  border: 1px solid #1C1C1C; }

.borderGreen {
  border: 1px solid #07C563; }

.borderLightGray {
  border: 1px solid #A7ACB8; }

.border-R-LightGray {
  border-right: 1px solid #A7ACB8; }

.border-Bot-LightGray {
  border-bottom: 1px solid #A7ACB8; }

.borderIconGray-9px {
  border: 9px solid #D8DEE7; }

.borderRed {
  border: 1px solid #ED7087; }

.borderReservation {
  border: 1px solid #CFD8E2; }

.borderBotLightGray {
  border-bottom: 1px solid #D9D9D9; }

.borderTopGray {
  border-top: 1px solid #474E56; }

.borderBotEaeaea {
  border-bottom: 1px solid #eaeaea; }

.realTime {
  font-size: 14px;
  width: 722px;
  height: 151px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #CECECE80;
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 100;
  background-color: #fff;
  padding: 22px; }
  .realTime.on {
    display: block; }
  .realTime.off {
    display: none; }
  .realTime .topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px; }
    .realTime .topSection .headerWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 18px; }
      .realTime .topSection .headerWrap p {
        font-size: 17px;
        font-weight: 700; }
      .realTime .topSection .headerWrap svg {
        color: #07C563; }
    .realTime .topSection .exit {
      width: 20px;
      color: #A7ACB8; }
  .realTime .contentSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px; }
    .realTime .contentSection .list ul {
      display: flex;
      gap: 10px; }
      .realTime .contentSection .list ul li {
        width: 170px;
        display: flex;
        text-align: start;
        align-items: center; }
        .realTime .contentSection .list ul li span {
          margin-right: 10px; }
          .realTime .contentSection .list ul li span:last-child {
            margin-right: 0; }
